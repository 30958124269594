import React, { useState, useEffect } from "react";
import {
  Spinner,
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import axios from "axios";

const AddNotification = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<string>("success");

  const isFormValid =
    title.trim() !== "" && message.trim() !== "" && url.trim() !== "";

  const sendNotification = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/sendWebNotification`,
        { title, message, url }
      );
      setToastMessage("Notification sent successfully!");
      setToastType("success");
      setToastVisible(true);
      setTitle("");
      setMessage("");
      setUrl("");
    } catch (error) {
      setToastMessage(
        "Failed to send notification. Check the console for more details."
      );
      setToastType("error");
      setToastVisible(true);
      console.error("Error sending notification:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (toastVisible) {
      const timer = setTimeout(() => {
        setToastVisible(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [toastVisible]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Notification" breadcrumbItem="Add Notfication" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col className="col-12">
                      <label htmlFor="title" className="col-form-label">
                        Title <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="Write the notification title here"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-12">
                      <label htmlFor="title" className="col-form-label">
                        Message <span className="text-danger">*</span>
                      </label>
                      <div>
                        <textarea
                          className="form-control"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeholder="Write the notification message here"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-12">
                      <label htmlFor="title" className="col-form-label">
                        Url <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          placeholder="Enter the target Url here"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-12">
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={!isFormValid || loading}
                          onClick={sendNotification}
                        >
                          {loading ? (
                            <>
                              <Spinner size="sm" color="light" />{" "}
                              <span>Send Notification</span>
                            </>
                          ) : (
                            "Send Notification"
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {toastVisible && (
          <div className="toast-container">
            <Toast
              className={`bg-${
                toastType === "success" ? "success" : "danger"
              } text-white`}
            >
              <ToastHeader>
                {toastType === "success" ? "Success" : "Error"}
              </ToastHeader>
              <ToastBody>{toastMessage}</ToastBody>
            </Toast>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AddNotification;
