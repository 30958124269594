import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "antd";
import { Container } from "reactstrap";
import moment from "moment";
import Breadcrumb from "Components/Common/Breadcrumb";

const columns = [
  {
    title: "#",
    key: "index",
    render: (_, __, index) => index + 1,
    width: 60,
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
  },
  {
    title: "Click Count",
    dataIndex: "clickCount",
    key: "clickCount",
  },
  {
    title: "Active Devices",
    dataIndex: "activeDevices",
    key: "activeDevices",
  },
  {
    title: "Sent At",
    dataIndex: "sentAt",
    key: "sentAt",
    render: (text) => moment(text).format("DD/MM/YYYY hh:mm A"),
  },
];

const NotificationDetail = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchNotificationDetail = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/fetch/notifications`,
        {
          params: { page, limit: pageSize },
        }
      );

      if (response.success) {
        setData(response.data);
        setPagination({
          current: page,
          pageSize,
          total: response.data.data.length,
        });
      }
    } catch (error) {
      console.error("Failed to fetch notification summary:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationDetail(pagination.current, pagination.pageSize);
  }, []);

  const handleTableChange = (pagination) => {
    fetchNotificationDetail(pagination.current, pagination.pageSize);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Notifications"
            breadcrumbItem="Notification Details"
          />
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: handleTableChange,
            }}
            loading={loading}
            rowKey="_id"
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NotificationDetail;
